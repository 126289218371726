import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContinueCheckout from '../components/ContinueCheckout.js'
import Progress from '../components/Progress.js'
import EndCheckout from '../components/EndCheckout.js'
import {CheckoutContext} from "../context/CheckoutContext"

import getStripe from '../stripe'


// markup
const Payment = () => {
    return (
        <main class="checkout">
        <section class="section">
      
        <Helmet title="Heyday ziedu piegāde" defer={false} />

        <div class="columns is-mobile">
          <div class="column pt-0">
            <Progress value={100} />
          </div>
          <div class="column is-one-third pt-0">
            <EndCheckout />
          </div>
        </div>



      <h1 class="title is-1">Paldies 🙏</h1>




      <div class="content">

        <div class="box">
          <p class="notification is-success">Tavs abonements ir apstiprināts!</p>
          <p class="subtitle">🚚 Tuvāko 10-30 minūšu saņemsi e-pastu, kurā varēsi norādīt piegādes laiku. </p>
          <p>Ja rodas kādi jautājumi, lūdzu raksti info@heyday.lv, mēs ar prieku uzklausīsim arī Tavu viedokli.</p>
          <hr />
          <p>Tālāk savu abonementu vari pārvaldīt <Link to="/manage">Klienta profilā</Link></p>
        </div>
     </div>  

       
       





      <div class="content">




      </div>

      {/* <p>

        {process.env.NODE_ENV === "development" ? (
          <>
            Dev
          </>
        ) : null}
        <br />

        <Link to="/">Home</Link>.
      </p> */}

      </section>



    </main>
  )
}

export default Payment
